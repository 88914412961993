<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }} - {{user?.user_name}} </ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }} </ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <ion-row>
          <ion-col></ion-col>
          <ion-col></ion-col>
          <ion-col>  
          <!--  <ion-button color="dark" style="width: 45px;" @click="openModalAdd">
              +
            </ion-button>-->
          </ion-col>
        </ion-row>
        <div style="margin-left: 24px;margin-right: 24px;">
          <div class="card">
            
              <table class="table table-responsive-sm table-striped table-align-middle">
                <thead>
                  <tr>
                    <th >Nombre del Usuario</th>
                    <th>Correo Electrónico</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="clientes in clientes.data" :key="clientes">
                    <td>{{clientes?.user_name}}</td>
                    <td>{{clientes?.email}}</td>
                    <td>
                      <!--<ion-button color="primary"  @click="openModalEdit(clientes)">
                        Editar
                      </ion-button>-->
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="page navigation example">
                <ul class="pagination justify-content-center">
                  <li  :class="{'page-item' : true , 'disabled' : !clientes.prev_page_url }">
                    <a class="page-link"   @click="page(clientes.prev_page_url)" tabindex="-1">Anterior</a>
                  </li>
                  <li class="page-item"><a class="page-link">{{clientes.current_page}}</a></li>
                  <li :class="{'page-item' : true , 'disabled' : !clientes.next_page_url }">
                    <a class="page-link"  @click="page(clientes.next_page_url)">Siguiente</a>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import { defineComponent } from 'vue';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { modalController,createAnimation } from '@ionic/vue';
import agregarCliente from './agregarCliente';
import editarCliente from './editarCliente';
import { mapGetters } from "vuex";
import axios from 'axios'

export default defineComponent({
  name: 'FolderPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return{
      clientes : [],
      colaborador_id : null,
      user : null
    }
  },
  mounted(){
   this.colaborador_id = this.$route.params.id 
   this.getUserColaborador()
   this.getClientesByColaborador()
  },
  methods:{
    getClientes(){
       axios.get('/api/users?role=Cliente')
      .then(res => {
        console.log(res.data.data)
        this.clientes = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getClientesByColaborador(){
       axios.get('/api/users/cliente/'+this.colaborador_id)
      .then(res => {
        console.log(res.data.data)
        this.clientes = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    page(url){
      axios.get(url)
       .then(res => {
         this.clientes = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    getUserColaborador(){
      axios.get('/api/users/'+this.colaborador_id)
       .then(res => {
         this.user = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
     async openModalAdd() {
    
      const modal = await modalController
        .create({
          component: agregarCliente,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
        })


   
      modal.present();

      modal.onDidDismiss().then((data) => {
        this.getClientes()
        console.log(data)
      })

    },
    async openModalEdit(cliente) {
    
      const modal = await modalController
        .create({
          component: editarCliente,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {cliente : cliente}
        })


   
      modal.present();
      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getClientes()
      })

    },
     enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style scoped>

</style>
